import React, {
  createContext,
  useEffect,
  useState,
  SyntheticEvent,
} from "react";

interface SnackbarContextType {
  isOpen: boolean;
  severity: any;
  messageInfo: SnackbarMessage;
  addSnackbarError: any;
  addSnackbarSuccess: any;
  addSnackbarWarning: any;
  handleClose: any;
  handleExited: any;
}

export interface SnackbarMessage {
  message: string;
  key: number;
}

export const SnackbarContext = createContext<SnackbarContextType>(null);

export function SnackbarContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState("success");

  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const [messageInfo, setMessageInfo] = useState<SnackbarMessage | undefined>(
    undefined
  );

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setIsOpen(true);
    } else if (snackPack.length && messageInfo && isOpen) {
      // Close an active snack when a new one is added
      setIsOpen(false);
    }
  }, [snackPack, messageInfo, isOpen]);

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const addSnackbarError = (message: string) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    setSeverity("error");
  };

  const addSnackbarWarning = (message: string) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    setSeverity("warning");
  };

  const addSnackbarSuccess = (message: string) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    setSeverity("success");
  };

  return (
    <SnackbarContext.Provider
      value={{
        isOpen,
        severity,
        addSnackbarError,
        addSnackbarSuccess,
        addSnackbarWarning,
        messageInfo,
        handleClose,
        handleExited,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}
