import React, { createContext, useEffect, useState } from "react";
import { LoginRequest, LoginResponse, User } from "../components/ModelTypes";
import { ServerApi } from "../components/ServerApi";
import {
  Cookies,
  getCookie,
  removeCookie,
  setCookie,
} from "../components/Utils";

interface AuthContextType {
  user: LoginResponse;
  login: (user: any, callback: VoidFunction) => void;
  logout: any;
  darkMode: boolean;
  setDarkMode: any;
  setDarkModePreference: any;
}

export const AuthContext = createContext<AuthContextType>(null);

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState<LoginResponse>(null);
  const [darkMode, setDarkMode] = useState(true);

  const setDarkModePreference = (darkModePreference: boolean) => {
    setDarkMode(darkModePreference);
    if (user) {
      ServerApi.updateUserDarkMode(user.userId, darkModePreference);
    }
  };

  useEffect(() => {
    if (user) {
      setDarkMode(user.prefersDarkMode);
    } else {
      setDarkMode(true);
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      const token = getCookie(Cookies.TOKEN);
      const userId = getCookie(Cookies.USER_ID);

      if (token !== "" && userId !== "") {
        ServerApi.getUserById(userId)
          .then((res) => {
            const userInfo: User = res.data as User;
            const myUser: LoginResponse = new LoginResponse();
            myUser.userId = userInfo.id;
            myUser.lastName = userInfo.lastName;
            myUser.firstName = userInfo.firstName;
            myUser.token = token;
            myUser.email = userInfo.email;
            myUser.prefersDarkMode = userInfo.prefersDarkMode;
            myUser.userRoleId = userInfo.userRoleId;
            setUser(myUser);
          })
          .catch((error: Error) => {
            setUser(null);
          });
      }
    }
  }, [user]);

  const login = (loginReq: LoginRequest, callback: VoidFunction) => {
    ServerApi.login(loginReq)
      .then((res) => {
        const loginRes = res.data as LoginResponse;
        if (loginRes && loginRes?.token !== "") {
          setCookie(Cookies.TOKEN, loginRes.token);
          setCookie(Cookies.USER_ID, loginRes.userId);
          setUser(loginRes);
          callback();
        }
      })
      .catch((error: Error) => {});
  };

  const logout = () => {
    removeCookie(Cookies.TOKEN);
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        darkMode,
        setDarkMode,
        login,
        logout,
        setDarkModePreference,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
