import React, { useContext } from "react";
import { Alert, AlertTitle, Button, IconButton, Snackbar } from "@mui/material";
import { SnackbarContext } from "../context/SnackbarProvider";
import CloseIcon from "@mui/icons-material/Close";

export default function MainSnackbar() {
  const { isOpen, messageInfo, severity, handleClose, handleExited } =
    useContext(SnackbarContext);

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert
        // variant="filled"
        severity={severity}
        action={
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      >
        {/* <AlertTitle>
          {severity.charAt(0).toUpperCase() + severity.slice(1)}
        </AlertTitle> */}
        {messageInfo ? messageInfo.message : undefined}
      </Alert>
    </Snackbar>
  );
}
