import axios, {AxiosPromise, AxiosRequestConfig} from 'axios';
import {BASE_URL, Cookies, getCookie} from './Utils';

function getBearerToken() {
  const token = getCookie(Cookies.TOKEN);
  return token !== "" ? `Bearer ${token}` : "";
}

function buildAuthHeader() {
  return {
    Authorization: getBearerToken(),
  };
}

export module HttpService {
  export function httpPost(url: string, data: {}): AxiosPromise {
    return axios({
      method: "post",
      baseURL: BASE_URL,
      url: `${url}`,
      data,
      headers: {
        ...buildAuthHeader(),
      },
    })
      .then(unpack)
      .catch((error) => {
        console.log("FAILED POST ATTEMPT");
        console.error(error);
      });
  }

  export function httpPut(url: string, data: {}): AxiosPromise {
    return axios({
      method: "put",
      baseURL: BASE_URL,
      url: `${url}`,
      data,
      headers: {
        ...buildAuthHeader(),
      },
    })
      .then(unpack)
      .catch((error) => {
        console.error(error);
      });
  }

  export function httpGet(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosPromise {
    return axios({
      method: "get",
      baseURL: BASE_URL,
      url: `${url}`,
      headers: {
        ...buildAuthHeader(),
      },
      ...(config && config.params && { params: config.params }),
    })
      .then(unpack)
      .catch((error) => {
        console.error(error);
      });
  }

  export function httpDelete(
    url: string,
    config?: AxiosRequestConfig
  ): AxiosPromise {
    return axios({
      method: "delete",
      baseURL: BASE_URL,
      url: `${url}`,
      headers: {
        ...buildAuthHeader(),
      },
      ...(config && config.params && { params: config.params }),
    })
      .then(unpack)
      .catch((error) => {
        console.error(error);
      });
  }

  function unpack(response): any {
    return response;
  }
}
