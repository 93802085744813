import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { Notes, TextFieldCopy } from "../CustomComponents";
import {
  AsceCodeTypes,
  CalculationTypes,
  DigitalCertificationTypes,
  ExposureCategoryTypes,
  State,
  StateDefault,
  StateNote,
} from "../ModelTypes";
import { ServerApi } from "../ServerApi";
import { Box } from "@mui/system";

const formModes = {
  ZERO_STATE: "ZERO_STATE",
  EDIT_STATE_DEFAULT: "EDIT_STATE_DEFAULT",
  VIEW_STATE_DEFAULT: "VIEW_STATE_DEFAULT",
};

function StateDefaults() {
  const { user } = useContext(AuthContext);

  const [formMode, setFormMode] = useState(formModes.ZERO_STATE);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const [createdBy, setCreatedBy] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);

  const [autoCompleteSelectedValue, setAutoCompleteSelectedValue] =
    useState("");
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState("");

  const [stateName, setStateName] = useState("");
  const [stateId, setStateId] = useState("");

  const [snowLoad, setSnowLoad] = useState(0);
  const [windSpeed, setWindSpeed] = useState(0);
  const [exposureCategory, setExposureCategory] = useState("");

  const [buildingCode, setBuildingCode] = useState("");
  const [asceCode, setAsceCode] = useState("");
  const [necCode, setNecCode] = useState("");

  const [stateNotes, setStateNotes] = useState<StateNote[]>([]);

  const [expirationDateRequired, setExpirationDateRequired] = useState(false);
  const [signedDateRequired, setSignedDateRequired] = useState(false);
  const [eeStampRequired, setEeStampRequired] = useState(false);
  const [seStampRequired, setSeStampRequired] = useState(false);
  const [wetStampRequired, setWetStampRequired] = useState(false);
  const [peStampOk, setPeStampOk] = useState(false);

  const [gravityCalcsRequired, setGravityCalcsRequired] = useState(false);
  const [windCalcsRequired, setWindCalcsRequired] = useState(false);
  const [rafterCalcsRequired, setRafterCalcsRequired] = useState(false);
  const [seismicCalcsRequired, setSeismicCalcsRequired] = useState(false);

  const [calculationsSelectValue, setCalculationsSelectValue] = useState([]);

  const [digitallyCertify, setDigitallyCertify] = useState("None");

  const isReadOnly: boolean = useMemo(
    () => formMode === formModes.VIEW_STATE_DEFAULT,
    [formMode]
  );
  const isEditMode: boolean = useMemo(
    () => formMode === formModes.EDIT_STATE_DEFAULT,
    [formMode]
  );

  const enableEditMode = () => setFormMode(formModes.EDIT_STATE_DEFAULT);
  const enableViewMode = () => setFormMode(formModes.VIEW_STATE_DEFAULT);

  useEffect(() => {
    getAllStateDefaults();
  }, []);

  const getAllStateDefaults = async () => {
    const { data } = await ServerApi.getAllUnitedStates();
    const myStates: State[] = data as State[];
    const myDefaults: StateDefault[] = myStates.map(
      (state: State) => state.townshipStateDefault
    );
    setAutoCompleteOptions(myDefaults);
  };

  const applyValuesToStateDefault = (stateDefault: StateDefault) => {
    stateDefault.stateName = stateName;
    stateDefault.snowLoad = snowLoad;
    stateDefault.windSpeed = windSpeed;
    stateDefault.exposureCategory = exposureCategory;
    stateDefault.buildingCode = buildingCode;
    stateDefault.asceCode = asceCode;
    stateDefault.necCode = necCode;
    stateDefault.expirationDateRequired = expirationDateRequired;
    stateDefault.necCode = necCode;
    stateDefault.expirationDateRequired = expirationDateRequired;
    stateDefault.signedDateRequired = signedDateRequired;
    stateDefault.eeStampRequired = eeStampRequired;
    stateDefault.seStampRequired = seStampRequired;
    stateDefault.peStampOk = peStampOk;
    stateDefault.wetStampRequired = wetStampRequired;
    stateDefault.gravityCalcsRequired = gravityCalcsRequired;
    stateDefault.windCalcsRequired = windCalcsRequired;
    stateDefault.rafterCalcsRequired = rafterCalcsRequired;
    stateDefault.seismicCalcsRequired = seismicCalcsRequired;
    stateDefault.digitallyCertify = digitallyCertify;
    return stateDefault;
  };
  useEffect(() => {
    console.log("use effect ran");
    let myCalcsArray = [];
    if (gravityCalcsRequired) myCalcsArray.push("Gravity");
    if (windCalcsRequired) myCalcsArray.push("Wind Uplift");
    if (rafterCalcsRequired) myCalcsArray.push("Rafter");
    if (seismicCalcsRequired) myCalcsArray.push("Seismic");
    setCalculationsSelectValue(myCalcsArray);
  }, [
    gravityCalcsRequired,
    windCalcsRequired,
    rafterCalcsRequired,
    seismicCalcsRequired,
  ]);

  const onCalculationsSelectChange = (event) => {
    const calcs = event.target.value;
    setCalculationsSelectValue(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
    setGravityCalcsRequired(calcs.includes("Gravity"));
    setWindCalcsRequired(calcs.includes("Wind Uplift"));
    setRafterCalcsRequired(calcs.includes("Rafter"));
    setSeismicCalcsRequired(calcs.includes("Seismic"));
  };

  const updateStateDefault = async () => {
    const state = new State();
    state.id = stateId;
    let stateDefault = new StateDefault();
    stateDefault = applyValuesToStateDefault(stateDefault);
    stateDefault.id = stateId;
    state.townshipStateDefault = stateDefault;
    ServerApi.updateStateDefault(state);
    enableViewMode();
    getAllStateDefaults();
  };

  // State note functions

  const createStateNote = async (note, link) => {
    const myNote = new StateNote();
    myNote.createdBy = user.userId;
    myNote.note = note;
    myNote.link = link;
    myNote.stateId = stateId;
    await ServerApi.createStateNote(myNote);
    getAllStateNotesByStateId(stateId);
  };

  const getAllStateNotesByStateId = async (stateId) => {
    if (stateId) {
      const { data } = await ServerApi.getAllStateNotesByStateId(stateId);
      setStateNotes(data || []);
    }
  };

  const deleteStateNoteById = async (stateNoteId) => {
    await ServerApi.deleteStateNote(stateNoteId);
    getAllStateNotesByStateId(stateId);
  };

  // Auto Complete functions and components

  const handleAutoCompleteInputChange = (event, input) => {
    if (typeof input === "string") {
      setAutoCompleteInputValue(input);
    } else if (typeof input === "object") {
      setAutoCompleteInputValue(input?.stateName);
    }
  };

  const onSelectOption = (e, option) => {
    enableViewMode();
    getAllStateNotesByStateId(option.id);
    setAutoCompleteSelectedValue(option.stateName);
    setCreatedAt(option.createdAt);
    // const defaults = option.townshipStateDefault;
    setStateId(option.id); // state abbreviation
    setStateName(option.stateName);
    setSnowLoad(option.snowLoad);
    setWindSpeed(option.windSpeed);
    setBuildingCode(option.buildingCode);
    setExposureCategory(option.exposureCategory);
    setAsceCode(option.asceCode);
    setNecCode(option.necCode);
    setExpirationDateRequired(option.expirationDateRequired);
    setSignedDateRequired(option.signedDateRequired);
    setPeStampOk(option.peStampOk);
    setEeStampRequired(option.eeStampRequired);
    setSeStampRequired(option.seStampRequired);
    setWetStampRequired(option.wetStampRequired);
    setGravityCalcsRequired(option.gravityCalcsRequired);
    setWindCalcsRequired(option.windCalcsRequired);
    setRafterCalcsRequired(option.rafterCalcsRequired);
    setSeismicCalcsRequired(option.seismicCalcsRequired);
    setDigitallyCertify(option.digitallyCertify);
  };

  const AutoCompleteRenderOption = ({
    props,
    option,
  }: {
    props: any;
    option: StateDefault;
  }) => {
    return (
      <Box component="li" {...props}>
        {option.stateName}
      </Box>
    );
  };

  const optionEqualityCheck = (option, value) => option.value === value.value;

  return (
    <Grid
      spacing={1}
      container
      alignItems={"center"}
      maxWidth={700}
      padding={1}
      paddingTop={4}
    >
      <Grid item xs={12}>
        <Autocomplete
          options={autoCompleteOptions}
          isOptionEqualToValue={optionEqualityCheck}
          autoComplete
          autoHighlight
          value={autoCompleteSelectedValue}
          onChange={onSelectOption}
          inputValue={autoCompleteInputValue}
          onInputChange={handleAutoCompleteInputChange}
          loading={false}
          noOptionsText={`No option for ${autoCompleteInputValue}`}
          getOptionLabel={(option) => option.stateName ?? option}
          popupIcon={<SearchIcon />}
          renderOption={(props, option) => (
            <AutoCompleteRenderOption
              key={option.id}
              props={props}
              option={option}
            />
          )}
          renderInput={(params) => (
            <TextField
              autoComplete="new-password"
              {...params}
              label="Search State Defaults"
            />
          )}
        />
      </Grid>
      {formMode !== formModes.ZERO_STATE && (
        <>
          <Grid item xs={8} textAlign="center">
            <Typography fontSize={20}>
              Defaults for {autoCompleteSelectedValue}
            </Typography>
          </Grid>

          {formMode === formModes.VIEW_STATE_DEFAULT && (
            <Grid item xs={4}>
              <Button fullWidth onClick={enableEditMode} variant="contained">
                Edit {autoCompleteSelectedValue} Default
              </Button>
            </Grid>
          )}
          {formMode === formModes.EDIT_STATE_DEFAULT && (
            <Grid item xs={4}>
              <Button
                fullWidth
                onClick={updateStateDefault}
                variant="contained"
              >
                Save {autoCompleteSelectedValue} Default
              </Button>
            </Grid>
          )}

          <Grid item xs={6}>
            <TextFieldCopy
              disabled={isReadOnly}
              label="Wind Speed (MPH)"
              copy
              type="number"
              setValue={setWindSpeed}
              value={windSpeed}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldCopy
              disabled={isReadOnly}
              label="Snow Load (PSF)"
              copy
              type="number"
              setValue={setSnowLoad}
              value={snowLoad}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldCopy
              disabled={isReadOnly}
              label="Building Code"
              copy
              setValue={setBuildingCode}
              value={buildingCode}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>ASCE Code</InputLabel>
              <Select
                disabled={isReadOnly}
                value={asceCode}
                label="ASCE Code"
                onChange={(event) => setAsceCode(event.target.value)}
              >
                {AsceCodeTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <InputLabel>Exposure Category</InputLabel>
              <Select
                disabled={isReadOnly}
                value={exposureCategory}
                label="Exposure Category"
                onChange={(event) => setExposureCategory(event.target.value)}
              >
                {ExposureCategoryTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextFieldCopy
              disabled={isReadOnly}
              label="National Electric Code"
              setValue={setNecCode}
              value={necCode}
            />
          </Grid>

          <Grid item xs={12}>
            <Card variant="outlined" sx={{ width: "100%" }}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={6} sm={4}>
                  <FormControlLabel
                    disabled={isReadOnly}
                    control={
                      <Checkbox
                        size="small"
                        checked={expirationDateRequired}
                        onChange={(event) =>
                          setExpirationDateRequired(event.target.checked)
                        }
                      />
                    }
                    label="Expiration Date"
                  />
                </Grid>

                <Grid item xs={6} sm={4}>
                  <FormControlLabel
                    disabled={isReadOnly}
                    control={
                      <Checkbox
                        size="small"
                        checked={signedDateRequired}
                        onChange={(event) =>
                          setSignedDateRequired(event.target.checked)
                        }
                      />
                    }
                    label="Signed Date"
                  />
                </Grid>

                <Grid item xs={6} sm={4}>
                  <FormControlLabel
                    disabled={isReadOnly}
                    control={
                      <Checkbox
                        size="small"
                        checked={eeStampRequired}
                        onChange={(event) =>
                          setEeStampRequired(event.target.checked)
                        }
                      />
                    }
                    label="EE Stamp (Greg)"
                  />
                </Grid>

                <Grid item xs={6} sm={4}>
                  <FormControlLabel
                    disabled={isReadOnly}
                    control={
                      <Checkbox
                        size="small"
                        checked={seStampRequired}
                        onChange={(event) =>
                          setSeStampRequired(event.target.checked)
                        }
                      />
                    }
                    label="SE Stamp (Cole)"
                  />
                </Grid>

                <Grid item xs={6} sm={4}>
                  <FormControlLabel
                    disabled={isReadOnly}
                    control={
                      <Checkbox
                        size="small"
                        checked={wetStampRequired}
                        onChange={(event) =>
                          setWetStampRequired(event.target.checked)
                        }
                      />
                    }
                    label="Wet Stamp"
                  />
                </Grid>

                <Grid item xs={6} sm={4}>
                  <FormControlLabel
                    disabled={isReadOnly}
                    control={
                      <Checkbox
                        size="small"
                        checked={peStampOk}
                        onChange={(event) => setPeStampOk(event.target.checked)}
                      />
                    }
                    label="PE Stamp OK (Scott)"
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel>Calculations</InputLabel>
              <Select
                multiple
                disabled={isReadOnly}
                value={calculationsSelectValue}
                label="Calculations"
                onChange={(event) => onCalculationsSelectChange(event)}
              >
                {CalculationTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth size="small">
              <InputLabel>Digitally Certify</InputLabel>
              <Select
                disabled={isReadOnly}
                value={digitallyCertify}
                label="Digitally Certify"
                onChange={(event) => setDigitallyCertify(event.target.value)}
              >
                {DigitalCertificationTypes.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Notes
              editable={isEditMode}
              title={`State Notes (${stateId})`}
              notesList={stateNotes}
              deleteNote={deleteStateNoteById}
              addNote={createStateNote}
            />
          </Grid>

          {formMode === formModes.VIEW_STATE_DEFAULT && (
            <Grid item xs={12}>
              <Button fullWidth onClick={enableEditMode} variant="contained">
                Edit {autoCompleteSelectedValue} Default
              </Button>
            </Grid>
          )}
          {formMode === formModes.EDIT_STATE_DEFAULT && (
            <Grid item xs={12}>
              <Button
                fullWidth
                onClick={updateStateDefault}
                variant="contained"
              >
                Save {autoCompleteSelectedValue} Default
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default StateDefaults;
