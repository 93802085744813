import React, { useContext } from "react";
import TownshipForm from "./components/ahjtools/TownshipForm";
import { AuthContext } from "./context/AuthProvider";
import NavBar from "./components/AppBar";
import Login from "./components/auth/Login";
import { Routes, Route, Link } from "react-router-dom";
import { RequireAuth } from "./components/auth/RequireAuth";
import Settings from "./components/Settings";
import StateDefaults from "./components/ahjtools/StateDefaults";
import { createTheme, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import Users from "./components/Users";
import MainSnackbar from "./components/Snackbar";
import { SnackbarContextProvider } from "./context/SnackbarProvider";

function App() {
  const { darkMode, user } = useContext(AuthContext);

  const theme = createTheme({
    palette: {
      primary: {
        main: "rgb(4, 138, 196)",
      },
      secondary: {
        main: "rgb(44, 76, 139)",
      },
      mode: darkMode ? "dark" : "light",
    },
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <ThemeProvider theme={theme}>
          <SnackbarContextProvider>
            <CssBaseline />
            <MainSnackbar />
            {user && <NavBar />}

            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth authorizedRoleIds={[0, 1, 2, 3, 4, 5, 6]}>
                    <TownshipForm />
                  </RequireAuth>
                }
              />
              <Route
                path="/database/defaults"
                element={
                  <RequireAuth authorizedRoleIds={[0, 1, 2, 3]}>
                    <StateDefaults />
                  </RequireAuth>
                }
              />
              <Route
                path="/settings"
                element={
                  <RequireAuth authorizedRoleIds={[0, 1, 2, 3, 4, 5, 6]}>
                    <Settings />
                  </RequireAuth>
                }
              />
              <Route
                path="/users"
                element={
                  <RequireAuth authorizedRoleIds={[0, 1, 2, 3]}>
                    <Users />
                  </RequireAuth>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route
                path="*"
                element={
                  <Link to="/" style={{ color: "#FFF" }}>
                    Oops! This page does not exist. Click here to return home.
                  </Link>
                }
              />
            </Routes>
          </SnackbarContextProvider>
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;
