import React, { useContext } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StorageRoundedIcon from "@mui/icons-material/StorageRounded";
import PeopleIcon from "@mui/icons-material/People";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import ArticleIcon from "@mui/icons-material/Article";
import CalculateIcon from "@mui/icons-material/Calculate";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";

export default function NavigationDrawer({ drawerOpen, setDrawerOpen }) {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const handleNavigate = (route) => {
    setDrawerOpen(false);
    navigate(route);
  };

  return (
    <Drawer
      anchor={"left"}
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <List>
        {listItems.map((item, index) => {
          return item.requiredRoleIds.includes(user.userRoleId) ? (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => handleNavigate(item.route)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ) : null;
        })}
      </List>
    </Drawer>
  );
}

const listItems = [
  {
    label: "Database",
    route: "/",
    icon: <StorageRoundedIcon />,
    requiredRoleIds: [0, 1, 2, 3, 4, 5, 6],
  },
  {
    label: "State Defaults",
    route: "/database/defaults",
    icon: <TuneRoundedIcon />,
    requiredRoleIds: [0, 1, 2, 3],
  },
  {
    label: "Users",
    route: "/users",
    icon: <PeopleIcon />,
    requiredRoleIds: [0, 3],
  },
  // {
  //   label: "Letter Generation",
  //   route: "/letters",
  //   icon: <ArticleIcon />,
  // },
  // {
  //   label: "Accounts",
  //   route: "/accounts",
  //   icon: <EngineeringRoundedIcon />,
  // },
  // {
  //   label: "Reports",
  //   route: "/reports",
  //   icon: <LeaderboardRoundedIcon />,
  // },
  // {
  //   label: "Calculations",
  //   route: "/calculations",
  //   icon: <CalculateIcon />,
  // },
];
