import axios, { AxiosError } from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SnackbarContext } from "../context/SnackbarProvider";
import { BASE_URL, Cookies, getCookie } from "./Utils";
import { throttle } from "lodash";
import { useDebouncedEffect } from "./CustomComponents";
import { Township } from "./ModelTypes";

interface QueryParam {
  value: string | object;
  required: boolean;
}

type BaseProps = {
  url: string;
  description: string;
  runOnStart: boolean;
  alert: boolean;
  params?: QueryParam[];
  debounce: boolean;
};

type GetProps = {
  method: "GET";
  payload?: never;
  params?: QueryParam[];
};
type PostProps = {
  method: "POST" | "DELETE" | "PUT";
  payload?: object;
  params?: QueryParam[];
};

type ConditionalProps = GetProps | PostProps;

type Props = BaseProps & ConditionalProps;

type FetchReturn = {
  data: any;
  loading: boolean;
  refetch: () => void;
  statusCode: number;
};

function getBearerToken() {
  const token = getCookie(Cookies.TOKEN);
  return token !== "" ? `Bearer ${token}` : "";
}

function buildAuthHeader() {
  return {
    Authorization: getBearerToken(),
  };
}

export function useFetch({
  url,
  method,
  payload,
  runOnStart,
  params,
  alert,
  description,
  debounce,
}: Props): FetchReturn {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState(-1);
  const { addSnackbarError, addSnackbarSuccess } = useContext(SnackbarContext);
  const isMounted = useRef(runOnStart);

  const paramsRequiredAndEmpty = () =>
    params?.map((param) => param.required && !param.value).includes(true);

  const fetchData = async () => {
    if (loading) {
      return;
    }
    if (runOnStart || !paramsRequiredAndEmpty()) {
      setLoading(true);
      await axios({
        method: method,
        baseURL: BASE_URL,
        data: payload,
        url: url,
        headers: {
          ...buildAuthHeader(),
        },
      })
        .then((response) => {
          if (alert === true) {
            addSnackbarSuccess(description);
          }
          setData(response.data);
        })
        .catch((error) => {
          addSnackbarError(`Failed: ${description}`);
          console.error(`Failed to ${method}: ${BASE_URL}${url}`, error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      isMounted.current = true;
    }
  };

  useDebouncedEffect(() => fetchData(), [url], debounce ? 400 : 0);

  const refetch = () => {
    fetchData();
  };

  return { data, loading, refetch, statusCode };
}

export function useGet({
  url,
  runOnStart,
  alert,
  params,
  description,
  debounce,
}: // method
BaseProps & GetProps): FetchReturn {
  return useFetch({
    url,
    method: "GET",
    runOnStart,
    alert,
    description,
    params,
    debounce,
  });
}

export function usePost({
  url,
  runOnStart,
  payload,
  alert,
  params,
  description,
  debounce,
}: BaseProps & PostProps): FetchReturn {
  const fetchResult = useFetch({
    url,
    method: "POST",
    runOnStart,
    payload,
    alert,
    params,
    description,
    debounce,
  });
  return fetchResult;
}

export function useDelete({
  url,
  runOnStart,
  payload,
  alert,
  params,
  description,
  debounce,
}: BaseProps & PostProps): FetchReturn {
  const fetchResult = useFetch({
    url,
    method: "DELETE",
    runOnStart,
    payload,
    alert,
    params,
    description,
    debounce,
  });
  return fetchResult;
}

//TOWNSHIP HOOKS
export function useCreateNewTownship(payload: Township) {
  return usePost({
    url: "/v1/townships/",
    method: "POST",
    runOnStart: false,
    payload,
    alert: false,
    params: [{ value: payload, required: true }],
    description: "Create new Township",
    debounce: false,
  });
}

export function useDeleteTownshipById(id) {
  return useDelete({
    url: `/v1/townships/${id}`,
    method: "DELETE",
    runOnStart: false,
    alert: false,
    description: "Delete Township",
    debounce: false,
  });
}

export function useGetAllTownshipOptions(searchString) {
  const split = searchString.split(",");
  const city = split[0]?.trim() || "";
  const state = split[1]?.trim() || "";
  return useGet({
    url: `/v1/townshipoptions/?city=${city}&state=${state}`,
    method: "GET",
    runOnStart: false,
    alert: false,
    params: [
      { value: city, required: true },
      { value: state, required: false },
    ],
    description: `Get Township Options ${city} ${state}`,
    debounce: true,
  });
}

export function useGetTownshipById(id) {
  return useGet({
    url: `/v1/townships/${id}`,
    method: "GET",
    runOnStart: false,
    params: [{ value: id, required: true }],
    alert: false,
    description: "Get Township by ID",
    debounce: false,
  });
}

export function useGetTownshipVerificationById(id) {
  return useGet({
    url: `/v1/townshipverification/${id}`,
    method: "GET",
    runOnStart: false,
    params: [{ value: id, required: true }],
    alert: false,
    description: "Get Township Verification by ID",
    debounce: false,
  });
}

export function useGetStateById(id) {
  return useGet({
    url: `/v1/states/${id}`,
    method: "GET",
    runOnStart: false,
    params: [{ value: id, required: true }],
    alert: false,
    description: "Get State by State Abbreviation",
    debounce: false,
  });
}
