import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  InputAdornment,
  List,
  Link as MUILink,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  TextField,
  TextFieldProps,
  Typography,
  DialogTitle,
  Tooltip,
  Card,
  Grid,
  Modal,
} from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { SnackbarContext } from "../context/SnackbarProvider";

export const formatDate = (date) => moment(date).format("M/D/YYYY");
export const formatDateTime = (date) => moment(date).format("M/D/YYYY h:mma");
export const formatDateFromNow = (date) => moment(date).fromNow();

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);
    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
};

type TextFieldCopyProps = TextFieldProps & {
  copy?: boolean;
  setValue?: any;
};

export function TextFieldCopy(props: TextFieldCopyProps) {
  const { copy, setValue, type, ...textFieldProps } = props;
  const { addSnackbarSuccess, addSnackbarWarning } =
    useContext(SnackbarContext);
  const handleCopy = () => {
    // console.table(props);
    if (props.value !== "") {
      addSnackbarSuccess(`Copied to Clipboard: ${props.value}`);
      navigator.clipboard.writeText(`${props.value}`);
    } else {
      addSnackbarWarning("Empty field. Cannot copy to Clipboard.");
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      {...textFieldProps}
      autoComplete="off"
      // value={value}
      onChange={handleChange}
      type={type}
      size="small"
      fullWidth
      InputProps={{
        endAdornment: copy ? (
          <InputAdornment position="end">
            <Tooltip title="Copy">
              <IconButton onClick={handleCopy} tabIndex={-1}>
                <ContentCopyIcon fontSize="small" color={"primary"} />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}

export function InfoModal({ title, icon = <InfoRoundedIcon />, children }) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Tooltip title={title}>
        <IconButton onClick={() => setModalOpen(true)}>{icon}</IconButton>
      </Tooltip>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card sx={{ maxWidth: 900, margin: 5 }}>
          <Grid container spacing={1} padding={5}>
            <Grid item xs={12} textAlign="center">
              <Typography fontSize={20}>{title}</Typography>
            </Grid>
            {children.map((child, index) => (
              <Grid item xs={12} key={index}>
                {child}
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                onClick={() => setModalOpen(false)}
                fullWidth
                variant="contained"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </>
  );
}

export function ConfirmDialogButton({
  disabled = false,
  actionTitle,
  actionDescription,
  action,
  color,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const confirmAction = () => {
    action();
    handleClose();
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{actionTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{actionDescription}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={confirmAction} autoFocus>
            {actionTitle}
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        tabIndex={-1}
        fullWidth
        color={color}
        variant="contained"
        onClick={handleOpen}
        disabled={disabled}
      >
        {actionTitle}
      </Button>
    </>
  );
}

export function ConfirmDialogIcon({
  disabled = false,
  actionTitle,
  actionDescription,
  action,
  icon,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const confirmAction = () => {
    action();
    handleClose();
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{actionTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{actionDescription}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={confirmAction} autoFocus>
            {actionTitle}
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title={actionTitle}>
        <IconButton
          tabIndex={-1}
          onClick={handleOpen}
          size="small"
          disabled={disabled}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </>
  );
}

export function Notes({
  title,
  notesList,
  editable = false,
  addNote,
  deleteNote,
}) {
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [newLink, setNewLink] = useState("");

  const handleCreateNote = () => {
    addNote(newNote, newLink);
    setNoteModalOpen(false);
    setNewLink("");
    setNewNote("");
  };
  const handleDeleteNote = (id) => deleteNote(id);

  return (
    <Card variant="outlined">
      <Grid container padding={1} spacing={1}>
        <Grid item xs={12}>
          <List
            disablePadding
            dense
            subheader={
              <ListSubheader
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {title}
                {editable && (
                  <Button
                    onClick={() => setNoteModalOpen(true)}
                    variant="contained"
                  >
                    Add Note
                  </Button>
                )}
              </ListSubheader>
            }
          >
            {notesList.map((note, index) => (
              <ListItem
                divider={notesList.length > index + 1}
                dense
                // disablePadding
                key={index}
                alignItems="flex-start"
                secondaryAction={
                  editable ? (
                    <ConfirmDialogIcon
                      icon={<DeleteForeverRoundedIcon />}
                      actionTitle="Delete Note"
                      actionDescription="Are you sure you want to delete this note?"
                      disabled={false}
                      action={() => handleDeleteNote(note.id)}
                    ></ConfirmDialogIcon>
                  ) : null
                }
              >
                <Tooltip
                  title={`${note.createdByUser.firstName} ${note.createdByUser.lastName}`}
                >
                  {/* <Tooltip title={formatDateTime(note.createdAt)}> */}
                  <ListItemAvatar>
                    <Avatar>{`${note.createdByUser.firstName[0]}${note.createdByUser.lastName[0]}`}</Avatar>
                  </ListItemAvatar>
                </Tooltip>
                <ListItemText
                  primary={
                    <Typography>
                      {note.note}
                      {note.link && (
                        <>
                          <br />
                          <MUILink
                            tabIndex={-1}
                            target="_blank"
                            href={note.link}
                          >
                            {note.link.substring(0, 30)}
                            {note.link.length > 27 && "..."}
                          </MUILink>
                        </>
                      )}
                    </Typography>
                  }
                  secondary={formatDateTime(note.createdAt)}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Modal
        open={noteModalOpen}
        onClose={() => setNoteModalOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ maxWidth: 900, margin: 5 }}>
          <Grid container spacing={1} padding={5}>
            <Grid item xs={12} textAlign="center">
              <Typography fontSize={20}>Add Note</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextFieldCopy
                label="Note (Required)"
                value={newNote}
                setValue={setNewNote}
              ></TextFieldCopy>
            </Grid>
            <Grid item xs={12}>
              <TextFieldCopy
                label="Link (Optional)"
                value={newLink}
                setValue={setNewLink}
              ></TextFieldCopy>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleCreateNote} fullWidth variant="contained">
                Submit
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => setNoteModalOpen(false)}
                fullWidth
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </Card>
  );
}
