import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";
import { LoginRequest } from "../ModelTypes";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, user } = useContext(AuthContext);

  const from = location.state["from"]["pathname"] || "/";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    const loginReq = new LoginRequest();
    loginReq.email = email;
    loginReq.password = password;
    login(loginReq, () => navigate(from, { replace: true }));
  };

  useEffect(() => {
    if (user) {
      navigate("/", { replace: true });
    }
    console.table(user);
  }, [user]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container maxWidth={400} padding={1} spacing={1}>
        {/* Shows this message if a URL is visited besides "/"*/}
        {from !== "/" && (
          <Grid item xs={12}>
            <Typography align="center">
              Please log in to view this page.
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Email Address"
            type={"email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="Password"
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" fullWidth onClick={handleLogin}>
            Login
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
