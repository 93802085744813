import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import NavigationDrawer from "./NavigationDrawer";
import { FormControlLabel, Switch } from "@mui/material";

export default function MenuAppBar() {
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const { user, logout, darkMode, setDarkModePreference } =
    useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavigate = (route) => {
    handleClose();
    navigate(route);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };
  const handleDarkModeChange = (event) => {
    setDarkModePreference(event.target.checked);
  };

  return (
    <AppBar position="sticky">
      <NavigationDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <IconButton
          size="large"
          edge="start"
          onClick={() => setDrawerOpen((prev) => !prev)}
        >
          <MenuIcon />
        </IconButton>
        {user && (
          <>
            <IconButton size="large" onClick={handleMenu} color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleNavigate("/settings")}>
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout {user.email}</MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={darkMode}
                      onChange={handleDarkModeChange}
                    />
                  }
                  label="Dark Mode"
                />
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
