import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ServerApi } from "./ServerApi";
import { User, UserRole, UserTitles } from "./ModelTypes";
import {
  ConfirmDialogButton,
  ConfirmDialogIcon,
  formatDate,
} from "./CustomComponents";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

const formModes = { EDIT: "EDIT", CREATE: "CREATE" };

export default function Users(): JSX.Element {
  useEffect(() => {
    getAllUsers();
    getAllUserRoles();
  }, []);

  const [formMode, setFormMode] = useState(formModes.EDIT);

  const [userList, setUserList] = useState([]);
  const [userRolesList, setUserRolesList] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);

  const [newUserModalOpen, setNewUserModalOpen] = useState(false);
  const [newUserFirstName, setNewUserFirstName] = useState("");
  const [newUserLastName, setNewUserLastName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserRole, setNewUserRole] = useState<UserRole>(userRolesList[0]);

  const handleBeginCreateUser = () => {
    setFormMode(formModes.CREATE);
    setNewUserModalOpen(true);
    setNewUserFirstName("");
    setNewUserLastName("");
    setNewUserEmail("");
    setNewUserPassword("");
    setNewUserRole(userRolesList[5]);
  };

  const handleBeginEditUser = (user) => {
    setFormMode(formModes.EDIT);
    setSelectedUser(user);
    setNewUserModalOpen(true);
    setNewUserFirstName(user.firstName);
    setNewUserLastName(user.lastName);
    setNewUserEmail(user.email);
    setNewUserPassword("");
    setNewUserRole(userRolesList[user.userRoleId]);
  };

  const handleCloseNewUserModal = () => setNewUserModalOpen(false);

  const getAllUserRoles = async () => {
    const { data } = await ServerApi.getAllUserRoles();
    setUserRolesList(data || []);
  };

  const getAllUsers = async () => {
    const { data } = await ServerApi.getAllUsers();
    setUserList(data || []);
  };

  const deleteUserById = async (userId) => {
    await ServerApi.deleteUser(userId);
    getAllUsers();
  };

  const handleSubmit = () => {
    if (formMode === formModes.CREATE) {
      handleCreateNewUser();
    }
    if (formMode === formModes.EDIT) {
      handleUpdateUser();
    }
  };

  const handleCreateNewUser = async () => {
    setNewUserModalOpen(false);
    let user = new User();
    user.email = newUserEmail;
    user.firstName = newUserFirstName;
    user.lastName = newUserLastName;
    user.userRoleId = newUserRole.id;
    user.passwordHash = newUserPassword;
    user.passwordSalt = "SALTY";
    await ServerApi.createOrUpdateUser(user);
    getAllUsers();
  };

  const handleUpdateUser = async () => {
    if (selectedUser) {
      let myUser = new User();
      myUser.id = selectedUser.id;
      myUser.firstName = newUserFirstName;
      myUser.lastName = newUserLastName;
      myUser.email = newUserEmail;
      myUser.userRoleId = newUserRole.id;
      myUser.passwordHash = newUserPassword;
      myUser.passwordSalt = "SALTY";
      await ServerApi.createOrUpdateUser(myUser);
    } else {
      alert("No user selected to update!");
    }
    getAllUsers();
  };

  return (
    <Grid
      spacing={1}
      container
      alignItems={"center"}
      maxWidth={1100}
      maxHeight="100vh"
      padding={1}
      paddingTop={3}
    >
      <Grid item xs={12}>
        <Button onClick={handleBeginCreateUser}>Create New User</Button>
      </Grid>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map((user, index) => (
              <TableRow key={index}>
                <TableCell>
                  {user.firstName} {user.lastName}
                </TableCell>
                <TableCell padding="none">{user.email}</TableCell>
                <TableCell padding="none">
                  {formatDate(user.createdAt)}
                </TableCell>
                <TableCell padding="none">{user.role}</TableCell>
                <TableCell padding="none">
                  <Tooltip title="Edit User">
                    <IconButton
                      size="small"
                      onClick={() => handleBeginEditUser(user)}
                    >
                      <EditRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <ConfirmDialogIcon
                    icon={<DeleteForeverRoundedIcon />}
                    action={() => deleteUserById(user.id)}
                    actionTitle="Delete User"
                    actionDescription={`Are you sure you want to delete ${user.firstName} ${user.lastName}?`}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Modal
        open={newUserModalOpen}
        onClose={handleCloseNewUserModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card>
          <Grid
            container
            flexDirection={"column"}
            minWidth={500}
            padding={2}
            spacing={1}
          >
            <Grid item xs={12}>
              <Typography variant="h4">
                {formMode === formModes.CREATE
                  ? "Create New User"
                  : "Edit User"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="First Name"
                autoComplete="off"
                value={newUserFirstName}
                onChange={(e) => setNewUserFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Last Name"
                autoComplete="off"
                value={newUserLastName}
                onChange={(e) => setNewUserLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Email Address"
                autoComplete="off"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Password"
                autoComplete="off"
                value={newUserPassword}
                type={"password"}
                onChange={(e) => setNewUserPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Title</InputLabel>
                <Select
                  value={newUserRole}
                  label="Title"
                  onChange={(event) =>
                    setNewUserRole(event.target.value as UserRole)
                  }
                >
                  {userRolesList.map((user, index) => (
                    <MenuItem key={index} value={user}>
                      {user.role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth onClick={handleSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </Grid>
  );
}
