import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthProvider";

export function RequireAuth({ children, authorizedRoleIds }) {
  const { user } = useContext(AuthContext);
  let location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (authorizedRoleIds.includes(user.userRoleId)) {
    return children;
  } else {
    return <div>Unauthorized</div>;
  }
}
