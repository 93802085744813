import { v4 as createUUID } from "uuid";

export class City {
  id: number;
  cityName: string = "";
  stateId: string = "";
  stateName: string = "";
  countyFips: number;
  countyName: string = "";
  latitude: number;
  longitude: number;
  population: number;
  density: number;
  isMilitary: boolean = false;
  isIncorporated: boolean = false;
  timezone: string = "";
  ranking: string = "";
  zips: number[] = [];
}

export class StateDefault {
  id: string = ""; //state abbreviation
  createdAt: Date;
  updatedAt: Date;
  stateName: string = "";
  snowLoad: number = 0;
  windSpeed: number = 0;
  exposureCategory: string = "";
  buildingCode: string = "";
  asceCode: string = "";
  necCode: string = "";
  peStampOk: boolean = false;
  expirationDateRequired: boolean = false;
  signedDateRequired: boolean = false;
  eeStampRequired: boolean = false;
  seStampRequired: boolean = false;
  wetStampRequired: boolean = false;
  digitalCertificationRequired: boolean = false;
  gravityCalcsRequired: boolean = false;
  windCalcsRequired: boolean = false;
  rafterCalcsRequired: boolean = false;
  seismicCalcsRequired: boolean = false;
  digitallyCertify: string = "";
}

export class State {
  id: string; // state abbreviation
  name: string;
  townshipStateDefault: StateDefault;
}

export class County {
  id: number; // County FIPS code
  name: string = "";
  fullName: string = "";
  stateId: string;
}

export class Township {
  id: string = createUUID();
  createdAt: Date;
  updatedAt: Date;
  isVerified: boolean = false;
  verifiedBy: string; // User ID
  dateVerified: Date;
  updatedBy: string; // User ID
  createdBy: string; // User ID
  cityName: string = "";
  county: string = "";
  stateId: string = "";
  snowLoad: number = 0;
  windSpeed: number = 0;
  exposureCategory: string = "";
  asceCode: string = "";
  necCode: string = "";
  peStampOk: boolean = false;
  buildingCode: string = "";
  expirationDateRequired: boolean = false;
  signedDateRequired: boolean = false;
  eeStampRequired: boolean = false;
  seStampRequired: boolean = false;
  wetStampRequired: boolean = false;
  gravityCalcsRequired: boolean = false;
  windCalcsRequired: boolean = false;
  rafterCalcsRequired: boolean = false;
  seismicCalcsRequired: boolean = false;
  digitallyCertify: string = "";
  latitude: number = 0;
  longitude: number = 0;
}

export class User {
  id: string = createUUID();
  createdAt: Date;
  updatedAt: Date;
  email: string;
  firstName: string;
  lastName: string;
  passwordHash: string;
  passwordSalt: string;
  userRoleId: number; // Foreign key to Roles
  prefersDarkMode: boolean;
}

export class UserRole {
  id: number;
  role: string;
}

export class LoginRequest {
  email: string;
  password: string;
}

export class LoginResponse {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  prefersDarkMode: boolean;
  userRoleId: number;
}

export const UserTitles: string[] = [
  "Admin", // 0
  "Structural Engineer", // 1
  "Electrical Engineer", // 2
  "QA Manager", // 3
  "Senior Account Manager", // 4
  "Account Manager", // 5
  "Print Manager", //6
];

export class TownshipNote {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string; //User Id
  note: string = "";
  link: string = "";
  file: string = ""; //TODO files to be implemented later
  townshipId: string = "";
}

export class StateNote {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string; //User Id
  note: string = "";
  link: string = "";
  file: string = ""; //TODO files to be implemented later
  stateId: string = "";
}

export class AutocompleteResult {
  id: string; //township uuid
  township: string;
  countyName: string;
  stateId: string;
  stateName: string;
  isVerified: boolean;
}

export const ExposureCategoryTypes: string[] = ["B", "C", "D"];

export const AsceCodeTypes: string[] = ["7-98", "7-05", "7-10", "7-16"];

export const CalculationTypes: string[] = [
  "Gravity",
  "Wind Uplift",
  "Rafter",
  "Seismic",
];

export const DigitalCertificationTypes: string[] = [
  "None",
  "First Page",
  "All Pages",
  "Split Pages",
  "Separate Letter and CAD",
];

export const UnitedStatesAndOutlyingAreas: string[] = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "District of Columbia",
  "American Samoa",
  "Guam",
  "Northern Mariana Islands",
  "Puerto Rico",
  "United States Minor Outlying Islands",
  "Virgin Islands, U.S.",
];

export const UnitedStatesAbbreviations: string[] = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
