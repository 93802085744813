import { Avatar, Button, Card, Grid, Modal, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthProvider";
import { ServerApi } from "./ServerApi";

export default function Settings() {
  const { user } = useContext(AuthContext);

  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleCloseModal = () => {
    setIsChangingPassword(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const handleChangePassword = () => {
    if (newPassword === confirmNewPassword) {
      ServerApi.updateUserPassword(user.userId, newPassword);
      setIsChangingPassword(false);
    } else {
      alert("Passwords do not match.");
    }
  };

  return (
    <>
      <Modal
        open={isChangingPassword}
        onClose={handleCloseModal}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card>
          <Grid
            container
            alignItems={"center"}
            maxWidth={600}
            spacing={3}
            padding={3}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleChangePassword}
                disabled={!oldPassword || !newPassword || !confirmNewPassword}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Modal>
      <Grid
        container
        alignItems={"center"}
        maxWidth={600}
        spacing={1}
        padding={1}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email Address"
            disabled
            value={user.email}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="First Name"
            disabled
            value={user.firstName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Last Name"
            disabled
            value={user.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setIsChangingPassword((prev) => !prev)}
          >
            {isChangingPassword ? "Cancel" : "Change Password"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
