import CircleIcon from "@mui/icons-material/Circle";

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

import CachedRoundedIcon from "@mui/icons-material/CachedRounded";

import { AuthContext } from "../../context/AuthProvider";
import {
  ConfirmDialogButton,
  formatDate,
  formatDateFromNow,
  InfoModal,
  Notes,
  TextFieldCopy,
} from "../CustomComponents";

import {
  AsceCodeTypes,
  AutocompleteResult,
  CalculationTypes,
  DigitalCertificationTypes,
  ExposureCategoryTypes,
  StateNote,
  Township,
  TownshipNote,
  UnitedStatesAbbreviations,
  User,
} from "../ModelTypes";
import { ServerApi } from "../ServerApi";
import {
  useCreateNewTownship,
  useGetAllTownshipOptions,
  useGetStateById,
  useGetTownshipById,
  useGetTownshipVerificationById,
} from "../ApiHooks";

const formModes = {
  ZERO_STATE: "ZERO_STATE",
  CREATE: "CREATE",
  VIEW: "VIEW",
};

function TownshipForm() {
  const { user } = useContext(AuthContext);

  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

  const [formMode, setFormMode] = useState<string>(formModes.ZERO_STATE);

  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [dateVerified, setDateVerified] = useState<Date>(null);
  const [verifiedByUser, setVerifiedByUser] = useState<User>(null);

  const [createdAt, setCreatedAt] = useState<Date>(null);
  const [createdByUser, setCreatedByUser] = useState<User>(null);
  const [updatedAt, setUpdatedAt] = useState<Date>(null);
  const [updatedByUser, setUpdatedByUser] = useState<User>(null);

  const [townshipId, setTownshipId] = useState<string>(null);

  const [autoCompleteSelectedValue, setAutoCompleteSelectedValue] =
    useState(null);
  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState("");
  const [enteredCity, setEnteredCity] = useState("");
  const [enteredStateId, setEnteredStateId] = useState("");

  const [city, setCity] = useState<string>("");
  const [stateId, setStateId] = useState<string>("");
  const [county, setCounty] = useState<string>("");

  const [snowLoad, setSnowLoad] = useState<number>(0);
  const [windSpeed, setWindSpeed] = useState<number>(0);
  const [exposureCategory, setExposureCategory] = useState<string>("");

  const [buildingCode, setBuildingCode] = useState<string>("");
  const [asceCode, setAsceCode] = useState<string>("");
  const [necCode, setNecCode] = useState<string>("");

  const [townshipNotes, setTownshipNotes] = useState<TownshipNote[]>([]);

  const [stateNotes, setStateNotes] = useState<StateNote[]>([]);

  const [expirationDateRequired, setExpirationDateRequired] = useState(false);
  const [signedDateRequired, setSignedDateRequired] = useState(false);
  const [eeStampRequired, setEeStampRequired] = useState(false);
  const [seStampRequired, setSeStampRequired] = useState(false);
  const [wetStampRequired, setWetStampRequired] = useState(false);
  const [peStampOk, setPeStampOk] = useState(false);

  const [gravityCalcsRequired, setGravityCalcsRequired] = useState(false);
  const [windCalcsRequired, setWindCalcsRequired] = useState(false);
  const [rafterCalcsRequired, setRafterCalcsRequired] = useState(false);
  const [seismicCalcsRequired, setSeismicCalcsRequired] = useState(false);

  const [calculationsSelectValue, setCalculationsSelectValue] = useState([]);
  const [longitude, setLongitude] = useState<number>(0);
  const [latitude, setLatitude] = useState<number>(0);

  const [digitallyCertify, setDigitallyCertify] = useState("");

  const highlightIf = (myBoolean: boolean) =>
    myBoolean ? "success.main" : "primary.text";

  const isViewMode: boolean = useMemo(
    () => formMode === formModes.VIEW,
    [formMode]
  );
  const isCreateMode: boolean = useMemo(
    () => formMode === formModes.CREATE,
    [formMode]
  );

  useEffect(() => {
    let myCalcsArray = [];
    if (gravityCalcsRequired) myCalcsArray.push("Gravity");
    if (windCalcsRequired) myCalcsArray.push("Wind Uplift");
    if (rafterCalcsRequired) myCalcsArray.push("Rafter");
    if (seismicCalcsRequired) myCalcsArray.push("Seismic");
    setCalculationsSelectValue(myCalcsArray);
  }, [
    gravityCalcsRequired,
    windCalcsRequired,
    rafterCalcsRequired,
    seismicCalcsRequired,
  ]);

  // Verifies that autocomplete input value is in the required format. Example: Salt Lake City, UT
  const isValidInputEntry: boolean = useMemo(
    () =>
      enteredCity !== "" && UnitedStatesAbbreviations.includes(enteredStateId),
    [enteredCity, enteredStateId]
  );

  const enableFormViewMode = () => setFormMode(formModes.VIEW);
  const enableFormCreateMode = () => setFormMode(formModes.CREATE);
  const enableFormZeroState = () => setFormMode(formModes.ZERO_STATE);

  const handleVerified = (event) => {
    updateTownshipVerification(
      townshipId,
      event.target.checked ? user.userId : null,
      event.target.checked
    );
    // setIsVerified(event.target.checked);
  };

  const clearTownship = () => {
    enableFormZeroState();
    setTownshipId(null);
    setCreatedAt(null);
    setUpdatedAt(null);
    setIsVerified(false);
    setDateVerified(null);
    setVerifiedByUser(null);
    setCreatedByUser(null);
    setUpdatedByUser(null);
    setCity("");
    setStateId("");
    setCounty("");
    setSnowLoad(0);
    setWindSpeed(0);
    setBuildingCode("");
    setExposureCategory("");
    setAsceCode("");
    setNecCode("");
    setExpirationDateRequired(false);
    setSignedDateRequired(false);
    setEeStampRequired(false);
    setSeStampRequired(false);
    setPeStampOk(false);
    setWetStampRequired(false);
    setGravityCalcsRequired(false);
    setWindCalcsRequired(false);
    setRafterCalcsRequired(false);
    setSeismicCalcsRequired(false);
    setDigitallyCertify("");
    setLatitude(0);
    setLongitude(0);
    setTownshipNotes([]);
    setStateNotes([]);
  };

  const applyStateToTownship = (township: Township) => {
    township.id = townshipId;
    township.isVerified = isVerified;
    township.createdBy = createdByUser?.id || user.userId;
    township.updatedBy = user.userId;
    township.cityName = city;
    township.stateId = stateId;
    township.county = county;
    township.snowLoad = snowLoad;
    township.windSpeed = windSpeed;
    township.exposureCategory = exposureCategory;
    township.buildingCode = buildingCode;
    township.asceCode = asceCode;
    township.necCode = necCode;
    township.expirationDateRequired = expirationDateRequired;
    township.necCode = necCode;
    township.expirationDateRequired = expirationDateRequired;
    township.signedDateRequired = signedDateRequired;
    township.eeStampRequired = eeStampRequired;
    township.seStampRequired = seStampRequired;
    township.peStampOk = peStampOk;
    township.wetStampRequired = wetStampRequired;
    township.gravityCalcsRequired = gravityCalcsRequired;
    township.windCalcsRequired = windCalcsRequired;
    township.rafterCalcsRequired = rafterCalcsRequired;
    township.seismicCalcsRequired = seismicCalcsRequired;
    township.digitallyCertify = digitallyCertify;

    return township;
  };

  // Township CRUD ops

  const [newTownshipPayload, setNewTownshipPayload] = useState<Township>();

  const { data, refetch: runCreateTownshipHook } =
    useCreateNewTownship(newTownshipPayload);

  useEffect(() => {
    runCreateTownshipHook();
  }, [newTownshipPayload]);

  const createNewTownship = async () => {
    if (
      buildingCode !== "" &&
      AsceCodeTypes.includes(asceCode) &&
      ExposureCategoryTypes.includes(exposureCategory) &&
      DigitalCertificationTypes.includes(digitallyCertify)
    ) {
      let township = new Township();
      township = applyStateToTownship(township);
      township.id = v4();
      township.createdBy = user.userId;
      township.updatedBy = user.userId;
      setNewTownshipPayload(township);
      setTownshipId(township.id);
      enableFormViewMode();
    } else {
      alert("Please fill out all required (red) fields.");
    }
  };

  const updateTownship = async () => {
    let township = new Township();
    township = applyStateToTownship(township);
    township.id = townshipId;
    township.updatedBy = user.userId;
    await ServerApi.createOrUpdateTownship(township);
    refetchTownshipById();
    enableFormViewMode();
  };

  const updateTownshipVerification = async (townshipId, userId, isVerified) => {
    await ServerApi.updateTownshipVerification(townshipId, userId, isVerified);
    // setIsVerified(isVerified);
    refetchTownshipVerificationById();
  };
  const {
    data: townshipData,
    refetch: refetchTownshipById,
    loading: townshipLoading,
  } = useGetTownshipById(townshipId);

  const {
    data: townshipVerificationData,
    loading: townshipVerificationLoading,
    refetch: refetchTownshipVerificationById,
  } = useGetTownshipVerificationById(townshipId);

  useEffect(() => {
    if (townshipVerificationData) {
      setVerifiedByUser(townshipVerificationData.verifiedByUser);
      setIsVerified(townshipVerificationData.isVerified);
      setDateVerified(townshipVerificationData.dateVerified);
    }
  }, [townshipVerificationData]);

  useEffect(() => {
    if (townshipData?.id) {
      setCreatedAt(townshipData.createdAt);
      setUpdatedAt(townshipData.updatedAt);
      setIsVerified(townshipData.isVerified);
      setDateVerified(townshipData.dateVerified);
      setVerifiedByUser(townshipData.verifiedByUser);
      setCreatedByUser(townshipData.createdByUser);
      setUpdatedByUser(townshipData.updatedByUser);
      setCity(townshipData.cityName);
      setStateId(townshipData.stateId);
      setCounty(townshipData.county);
      setSnowLoad(townshipData.snowLoad);
      setWindSpeed(townshipData.windSpeed);
      setBuildingCode(townshipData.buildingCode);
      setExposureCategory(townshipData.exposureCategory);
      setAsceCode(townshipData.asceCode);
      setNecCode(townshipData.necCode);
      setExpirationDateRequired(townshipData.expirationDateRequired);
      setSignedDateRequired(townshipData.signedDateRequired);
      setEeStampRequired(townshipData.eeStampRequired);
      setSeStampRequired(townshipData.seStampRequired);
      setPeStampOk(townshipData.peStampOk);
      setWetStampRequired(townshipData.wetStampRequired);
      setGravityCalcsRequired(townshipData.gravityCalcsRequired);
      setWindCalcsRequired(townshipData.windCalcsRequired);
      setRafterCalcsRequired(townshipData.rafterCalcsRequired);
      setSeismicCalcsRequired(townshipData.seismicCalcsRequired);
      setDigitallyCertify(townshipData.digitallyCertify);
      setLatitude(townshipData.latitude);
      setLongitude(townshipData.longitude);
      setTownshipNotes(townshipData.township_notes);
    }
  }, [townshipData]);

  const deleteTownship = async () => {
    await ServerApi.deleteTownship(townshipId);
    clearTownship();
  };

  const {
    data: stateData,
    refetch: refetchStateById,
    loading: stateLoading,
  } = useGetStateById(stateId);

  useEffect(() => {
    if (stateData) {
      setStateNotes(stateData.state_notes);
      if (isCreateMode) {
        const d = stateData.townshipStateDefault;
        setSnowLoad(d.snowLoad);
        setWindSpeed(d.windSpeed);
        setBuildingCode(d.buildingCode);
        setExposureCategory(d.exposureCategory);
        setAsceCode(d.asceCode);
        setNecCode(d.necCode);
        setExpirationDateRequired(d.expirationDateRequired);
        setSignedDateRequired(d.signedDateRequired);
        setEeStampRequired(d.eeStampRequired);
        setSeStampRequired(d.seStampRequired);
        setPeStampOk(d.peStampOk);
        setWetStampRequired(d.wetStampRequired);
        setGravityCalcsRequired(d.gravityCalcsRequired);
        setWindCalcsRequired(d.windCalcsRequired);
        setRafterCalcsRequired(d.rafterCalcsRequired);
        setSeismicCalcsRequired(d.seismicCalcsRequired);
        setDigitallyCertify(d.digitallyCertify);
      }
    }
  }, [stateData]);

  // Township note CRUD ops

  const createTownshipNote = async (note, link) => {
    const myNote = new TownshipNote();
    myNote.createdBy = user.userId;
    myNote.note = note;
    myNote.link = link;
    myNote.townshipId = townshipId;
    await ServerApi.createTownshipNote(myNote);
    refetchTownshipById();
  };

  const deleteTownshipNoteById = async (townshipNoteId) => {
    await ServerApi.deleteTownshipNote(townshipNoteId);
    refetchTownshipById();
  };

  // Auto Complete functions and components

  const { data: townshipOptionsData, loading: townshipOptionsLoading } =
    useGetAllTownshipOptions(autoCompleteInputValue);

  useEffect(() => {
    if (townshipOptionsData) {
      setAutoCompleteOptions(townshipOptionsData);
    }
  }, [townshipOptionsData]);

  const beginCreateNewTownshipFromInput = () => {
    clearTownship();
    if (isValidInputEntry) {
      setAutoCompleteOpen(false);
      setIsVerified(false);
      setCity(enteredCity);
      setStateId(enteredStateId);
      setCounty("");
      enableFormCreateMode();
    } else {
      alert("Required format: City, ST (Example: Salt Lake City, UT)");
    }
  };

  const handleAutoCompleteInputChange = (event, newInputValue) => {
    const cityInput = newInputValue.split(",")[0] || "";
    const stateInput = newInputValue.split(",")[1] || "";
    setAutoCompleteInputValue(newInputValue);
    setEnteredCity(cityInput.trim());
    setEnteredStateId(stateInput.trim());
  };

  const onSelectOption = (e, option: AutocompleteResult) => {
    if (option.id) {
      setTownshipId(option.id);
      setAutoCompleteOpen(false);
      enableFormViewMode();
    }
  };

  const buildVerificationLabel = () => {
    if (isVerified && verifiedByUser && dateVerified) {
      return `Verified by ${verifiedByUser.firstName} ${
        verifiedByUser.lastName
      } ${formatDateFromNow(dateVerified)}`;
    }
    if (isVerified && !verifiedByUser && !dateVerified) {
      return "Verified";
    }
    return "Unverified";
  };

  const optionEqualityCheck = (option, value) => option.value === value.value;

  const AutoCompleteRenderOption = ({ props, option }) => {
    const marginLeft = 1; //left margin for icons
    const fontSize = 12; //font size for icons
    return (
      <Box {...props}>
        {option.township}, {option.stateId}
        {option.id && option.isVerified === true && (
          <CircleIcon color="success" sx={{ marginLeft, fontSize }} />
        )}
        {option.id && option.isVerified === false && (
          <CircleIcon color="warning" sx={{ marginLeft, fontSize }} />
        )}
      </Box>
    );
  };

  const NoResults = () => {
    return autoCompleteInputValue === "" ? (
      <Typography align="center">Search AHJ Database</Typography>
    ) : (
      <Grid spacing={1} container alignItems={"center"} padding={1}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={beginCreateNewTownshipFromInput}
          >
            Create New Township: {autoCompleteInputValue}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <List
      disablePadding
      sx={{
        width: "100%",
        maxWidth: 700,
        position: "relative",
        overflow: "auto",
      }}
    >
      <ListSubheader disableGutters sx={{ zIndex: 1000 }}>
        <Grid container alignItems={"center"} padding={1}>
          <Grid item xs={12}>
            <Autocomplete
              options={autoCompleteOptions}
              isOptionEqualToValue={optionEqualityCheck}
              open={autoCompleteOpen}
              onOpen={() => setAutoCompleteOpen(true)}
              onClose={() => {
                setAutoCompleteOpen(false);
                setAutoCompleteOptions([]);
              }}
              onBlur={() => {
                setAutoCompleteOpen(false);
                setAutoCompleteOptions([]);
              }}
              clearOnBlur
              onChange={onSelectOption}
              autoComplete
              autoHighlight
              blurOnSelect
              // filterOptions={createFilterOptions({ limit: 50 })}
              value={autoCompleteSelectedValue}
              inputValue={autoCompleteInputValue}
              onInputChange={handleAutoCompleteInputChange}
              loading={townshipOptionsLoading}
              getOptionLabel={(option) =>
                `${option.township}, ${option.stateId}` ?? option
              }
              // popupIcon={<SearchIcon />}
              noOptionsText={<NoResults />}
              renderOption={(props, option) => (
                <AutoCompleteRenderOption
                  key={props.id}
                  props={props}
                  option={option}
                />
              )}
              renderInput={(params) => (
                <TextField {...params} label="Search AHJ Database" />
              )}
            />
          </Grid>
          <Grid item spacing={1} container>
            {isViewMode && (
              <>
                <Grid item xs={6}>
                  <Button fullWidth onClick={clearTownship} variant="contained">
                    Close
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={updateTownship}
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                </Grid>
              </>
            )}
            {isCreateMode && (
              <>
                <Grid item xs={6}>
                  <Button fullWidth onClick={clearTownship} variant="contained">
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={createNewTownship}
                    variant="contained"
                  >
                    Create
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </ListSubheader>

      <ListItem sx={{ display: "flex", justifyContent: "center" }}>
        {townshipLoading ? (
          <CircularProgress />
        ) : (
          <Grid spacing={1} container alignItems={"center"} width="100%">
            {formMode !== formModes.ZERO_STATE && (
              <>
                {formMode !== formModes.CREATE && (
                  <>
                    <Grid item xs={10}>
                      {townshipVerificationLoading ? (
                        <LinearProgress />
                      ) : (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={isVerified}
                              onChange={handleVerified}
                            />
                          }
                          label={buildVerificationLabel()}
                        />
                      )}
                    </Grid>

                    <Grid item xs={1}>
                      <Tooltip title="Refresh">
                        <IconButton onClick={refetchTownshipById}>
                          <CachedRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    <Grid item xs={1}>
                      <InfoModal title={"Township Information"}>
                        <TextFieldCopy
                          disabled
                          copy={false}
                          label="Created By"
                          value={`${createdByUser?.firstName} ${
                            createdByUser?.lastName
                          } ${formatDate(createdAt)} (${formatDateFromNow(
                            createdAt
                          )})`}
                        />
                        <TextFieldCopy
                          disabled
                          label="Modified By"
                          copy={false}
                          value={`${updatedByUser?.firstName} ${
                            updatedByUser?.lastName
                          } ${formatDate(updatedAt)} (${formatDateFromNow(
                            updatedAt
                          )})`}
                        />
                        <TextFieldCopy
                          disabled
                          label="Verified By"
                          copy={false}
                          value={`${verifiedByUser?.firstName} ${
                            verifiedByUser?.lastName
                          } ${formatDate(dateVerified)} (${formatDateFromNow(
                            dateVerified
                          )})`}
                        />
                        <TextFieldCopy
                          disabled
                          label="Township ID"
                          value={townshipId}
                        />
                        <ConfirmDialogButton
                          actionTitle={"Delete Township"}
                          action={deleteTownship}
                          actionDescription={`Are you sure you want to delete ${city}, ${stateId}?`}
                          color="error"
                        />
                      </InfoModal>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextFieldCopy
                    label="Township"
                    error={city === ""}
                    copy
                    setValue={setCity}
                    value={city}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>State</InputLabel>
                    <Select
                      error={stateId === ""}
                      value={stateId}
                      label="State"
                      onChange={(event) => setStateId(event.target.value)}
                    >
                      {UnitedStatesAbbreviations.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldCopy
                    label="County"
                    copy
                    setValue={setCounty}
                    value={county}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldCopy
                    label="Wind Speed (MPH)"
                    copy
                    type="number"
                    setValue={setWindSpeed}
                    value={windSpeed}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldCopy
                    label="Snow Load (PSF)"
                    copy
                    type="number"
                    setValue={setSnowLoad}
                    value={snowLoad}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextFieldCopy
                    error={buildingCode === ""}
                    label="Building Code"
                    name="test"
                    copy
                    value={buildingCode}
                    setValue={setBuildingCode}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>ASCE Code</InputLabel>
                    <Select
                      error={asceCode === ""}
                      value={asceCode}
                      label="ASCE Code"
                      onChange={(event) => setAsceCode(event.target.value)}
                    >
                      {AsceCodeTypes.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Exposure Category</InputLabel>
                    <Select
                      value={exposureCategory}
                      error={isCreateMode && exposureCategory === ""}
                      label="Exposure Category"
                      onChange={(event) =>
                        setExposureCategory(event.target.value)
                      }
                    >
                      {ExposureCategoryTypes.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextFieldCopy
                    copy
                    label="National Electrical Code"
                    setValue={setNecCode}
                    value={necCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Grid container spacing={1} padding={1}>
                      <Grid item xs={12}>
                        <Typography>Special Requirements</Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(expirationDateRequired),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={expirationDateRequired}
                              onChange={(event) =>
                                setExpirationDateRequired(event.target.checked)
                              }
                            />
                          }
                          label="Expiration Date"
                        />
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(eeStampRequired),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={eeStampRequired}
                              onChange={(event) =>
                                setEeStampRequired(event.target.checked)
                              }
                            />
                          }
                          label="Electrical Required (Greg)"
                        />
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(seStampRequired),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={seStampRequired}
                              onChange={(event) =>
                                setSeStampRequired(event.target.checked)
                              }
                            />
                          }
                          label="SE Stamp (Cole IL, Heath TX)"
                        />
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(wetStampRequired),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={wetStampRequired}
                              onChange={(event) =>
                                setWetStampRequired(event.target.checked)
                              }
                            />
                          }
                          label="Wet Stamp"
                        />
                      </Grid>

                      <Grid item xs={6} sm={4}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(peStampOk),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={peStampOk}
                              onChange={(event) =>
                                setPeStampOk(event.target.checked)
                              }
                            />
                          }
                          label="Electrical Required (Scott)"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper
                    variant="outlined"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Grid container spacing={1} padding={1}>
                      <Grid item xs={12}>
                        <Typography>Calculations</Typography>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(gravityCalcsRequired),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={gravityCalcsRequired}
                              onChange={(event) =>
                                setGravityCalcsRequired(event.target.checked)
                              }
                            />
                          }
                          label="Gravity"
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(windCalcsRequired),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={windCalcsRequired}
                              onChange={(event) =>
                                setWindCalcsRequired(event.target.checked)
                              }
                            />
                          }
                          label="Wind Uplift"
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(rafterCalcsRequired),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={rafterCalcsRequired}
                              onChange={(event) =>
                                setRafterCalcsRequired(event.target.checked)
                              }
                            />
                          }
                          label="Rafter"
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(signedDateRequired),
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={signedDateRequired}
                              onChange={(event) =>
                                setSignedDateRequired(event.target.checked)
                              }
                            />
                          }
                          label="Point Loads"
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <FormControlLabel
                          sx={{
                            color: highlightIf(seismicCalcsRequired),
                          }}
                          // disabled={isReadOnly}
                          control={
                            <Checkbox
                              size="small"
                              checked={seismicCalcsRequired}
                              onChange={(event) =>
                                setSeismicCalcsRequired(event.target.checked)
                              }
                            />
                          }
                          label="Seismic"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Digitally Certify</InputLabel>
                    <Select
                      sx={{
                        color: highlightIf(digitallyCertify !== "None"),
                      }}
                      value={digitallyCertify}
                      error={digitallyCertify === ""}
                      label="Digitally Certify"
                      onChange={(event) =>
                        setDigitallyCertify(event.target.value)
                      }
                    >
                      {DigitalCertificationTypes.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    target="_blank"
                    href={`https://www.google.com/search?q=Adopted+Building+Codes+${city},+${stateId}`}
                  >
                    Google
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    target="_blank"
                    href={`https://hazards.atcouncil.org/#/wind?address=${city}%2C%20${stateId}&lat=${latitude}&lng=${longitude}`}
                  >
                    ATC Hazards
                  </Button>
                </Grid>
                {!isCreateMode && (
                  <Grid item xs={12}>
                    <Notes
                      editable={true}
                      title={`Township Notes (${city}, ${stateId})`}
                      notesList={townshipNotes}
                      addNote={createTownshipNote}
                      deleteNote={deleteTownshipNoteById}
                    />
                  </Grid>
                )}
                {stateNotes && (
                  <Grid item xs={12}>
                    <Notes
                      title={`State Notes (${stateId})`}
                      notesList={stateNotes}
                      deleteNote={null}
                      addNote={null}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
      </ListItem>
    </List>
  );
}

export default TownshipForm;
