import { AxiosPromise } from "axios";
import {
  TownshipNote,
  State,
  Township,
  AutocompleteResult,
  StateNote,
  User,
  UserRole,
  LoginResponse,
  LoginRequest,
} from "./ModelTypes";
import { HttpService } from "./HttpService";

export module ServerApi {
  export function login(loginReq: LoginRequest): AxiosPromise<LoginResponse> {
    return HttpService.httpPost("/v1/login", loginReq);
  }

  export function createOrUpdateTownship(
    township: Township
  ): AxiosPromise<Township> {
    return HttpService.httpPost("/v1/townships", township);
  }

  export function updateTownshipVerification(
    townshipId: string,
    userId: string,
    isVerified: boolean
  ) {
    return HttpService.httpPut(`/v1/townshipverifictaion/`, {
      userId,
      isVerified,
      townshipId,
    });
  }

  export function deleteTownship(townshipId: string): AxiosPromise<Township> {
    return HttpService.httpDelete(`/v1/townships/${townshipId}`);
  }

  export function updateStateDefault(state: State): AxiosPromise<State> {
    return HttpService.httpPut("/v1/states", state);
  }

  export function getStateDefault(stateId: string): AxiosPromise<State> {
    return HttpService.httpGet(`/v1/states/${stateId}`);
  }

  export function getAllUnitedStates(): AxiosPromise<State[]> {
    return HttpService.httpGet(`/v1/states`);
  }

  export function createTownshipNote(
    townshipNote: TownshipNote
  ): AxiosPromise<TownshipNote[]> {
    return HttpService.httpPost(`/v1/townshipnotes`, townshipNote);
  }

  export function getAllTownshipNotesByTownshipId(
    townshipId: string
  ): AxiosPromise<TownshipNote[]> {
    return HttpService.httpGet(`/v1/townshipnotes/${townshipId}`);
  }

  export function deleteTownshipNote(
    townshipNoteId: string
  ): AxiosPromise<TownshipNote> {
    return HttpService.httpDelete(`/v1/townshipnotes/${townshipNoteId}`);
  }
  export function createStateNote(
    stateNote: StateNote
  ): AxiosPromise<StateNote[]> {
    return HttpService.httpPost(`/v1/statenotes`, stateNote);
  }

  export function getAllStateNotesByStateId(
    stateId: string
  ): AxiosPromise<StateNote[]> {
    return HttpService.httpGet(`/v1/statenotes/${stateId}`);
  }

  export function deleteStateNote(
    stateNoteId: string
  ): AxiosPromise<StateNote> {
    return HttpService.httpDelete(`/v1/statenotes/${stateNoteId}`);
  }

  export function createOrUpdateUser(user: User): AxiosPromise<User[]> {
    return HttpService.httpPost(`/v1/users/`, user);
  }

  export function updateUserPassword(
    userId: string,
    newPassword: string
  ): AxiosPromise<User[]> {
    return HttpService.httpPut(`/v1/userpassword/`, {
      userId,
      newPassword,
    });
  }

  export function updateUserDarkMode(
    userId: string,
    prefersDarkMode: boolean
  ): AxiosPromise<User[]> {
    return HttpService.httpPut(`/v1/userdarkmode/`, {
      userId,
      prefersDarkMode,
    });
  }

  export function getUserById(userId: string): AxiosPromise<User> {
    return HttpService.httpGet(`/v1/users/${userId}`);
  }

  export function getAllUsers(): AxiosPromise<User[]> {
    return HttpService.httpGet(`/v1/users/`);
  }

  export function deleteUser(userId: string): AxiosPromise<User> {
    return HttpService.httpDelete(`/v1/users/${userId}`);
  }

  export function getUserRoleById(roleId: string): AxiosPromise<UserRole> {
    return HttpService.httpGet(`/v1/userroles/${roleId}`);
  }

  export function getAllUserRoles(): AxiosPromise<UserRole[]> {
    return HttpService.httpGet(`/v1/userroles/`);
  }
}
